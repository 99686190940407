import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import reportWebVitals from './reportWebVitals';
import {Router, Switch, Route, Redirect} from "react-router-dom";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core";


// Pages
import Login from "./Pages/Login/Login";
import {amber} from "@material-ui/core/colors";
const hist = createBrowserHistory();
const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#3964C3'
        },
        primary: {
            main: '#FFA500',
            contrastText: '#FFFFFF'
        }
    }
});
ReactDOM.render(
    <MuiThemeProvider theme={theme}>
    <Router history={hist}>
        <Switch>
            <Redirect exact path={'/'} to={'/login'}/>
            <Route path={'/login'} component={Login}/>
        </Switch>
    </Router>
    </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
