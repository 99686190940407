import firebase from 'firebase/app';
import 'firebase/auth';
export const firebaseConfig = {
    apiKey: "AIzaSyAdD4fg91N_YyiI7Toj-RcW18jqFLWFYP4",
    authDomain: "el-jordan.firebaseapp.com",
    databaseURL: "https://el-jordan.firebaseio.com",
    projectId: "el-jordan",
    storageBucket: "el-jordan.appspot.com",
    messagingSenderId: "422925201542",
    appId: "1:422925201542:web:ce793d571ccba32d78a107",
    measurementId: "G-G87DEMJZF8"
};

export const app = firebase.initializeApp(firebaseConfig);

export default firebase;